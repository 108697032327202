import React from 'react'

import Layout from '../../../../components/Layout'
import ToolsTabs from '../../../../components/marketplace/tools/ToolsTabs'

export default class AdminNavbar extends React.Component {
  render() {
    return (
      <Layout noFooter active="tools" requireAuth>
        <ToolsTabs active="interest-simulator" />
        <iframe
          title="brand_infogram"
          src="https://infogram.com/job-application-simulator-1h7v4p5zr39d6k0?live"
          className="full-page-iframe"
        />
      </Layout>
    )
  }
}
