import React from 'react'
import { Link } from 'gatsby'

export default class ToolsTabsContainer extends React.Component {
  render() {
    const { active } = this.props

    return (
      <div className="marketingTabsContainer is-hidden-mobile invert-colors small">
        <div className="links-container">
          <Link
            to="/marketplace/tools/university-explorer"
            id="playbooksTab"
            className={`link ${active === 'university-explorer' ? 'active' : ''}`}
          >
            University Explorer
          </Link>
          <Link
            to="/marketplace/tools/acceptance-simulator"
            id="trackingTab"
            className={`link ${active === 'acceptance-simulator' ? 'active' : ''} disabled`}
            style={{
              pointerEvents: 'none',
            }}
          >
            Offer Acceptance Simulator
          </Link>
          <Link
            to="/marketplace/tools/interest-simulator"
            id="competitorsTab"
            className={`link ${active === 'interest-simulator' ? 'active' : ''} disabled`}
            style={{
              pointerEvents: 'none',
            }}
          >
            Job Application Simulator
          </Link>
        </div>
      </div>
    )
  }
}
